/* @import url('https://fonts.googleapis.com/css2?family=Bangers&family=Carter+One&family=Lato:wght@400;700&family=M+PLUS+Rounded+1c:wght@300&family=Poppins:wght@300;400&family=Roboto&family=Rubik&display=swap');
 */@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=M+PLUS+Rounded+1c&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@font-face {
  font-family: "Helvetica Neue Light";   /*Can be any text*/
  src: local("HelveticaNeue"),
    url("./assets/fonts/helvetica-neue/HelveticaNeueLight.ttf") format("ttf");
}

@font-face {
  font-family: "Helvetica Neue";   /*Can be any text*/
  src: local("HelveticaNeue"),
    url("./assets/fonts/helvetica-neue/HelveticaNeueMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue Roman";   /*Can be any text*/
  src: local("HelveticaNeue"),
    url("./assets/fonts/helvetica-neue/HelveticaNeue-Roman.otf") format("otf");
}

@font-face {
  font-family: "Helvetica Neue Bold";   /*Can be any text*/
  src: local("HelveticaNeueBold"),
    url("./assets/fonts/helvetica-neue/HelveticaNeueBold.ttf") format("truetype");
}

/* RESET */

  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* scroll-behavior: smooth; */
}

textarea {
  resize: none;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

body {
  background-color: #161616;
}

input, textarea {
  border: none;
  outline: none;
}

.displayNone {
  display: none;
}

.absolute {
  position: absolute;
}


/* Variables */

:root {
  --TITLE-GRADIENT-COLOR: linear-gradient(270deg, #6D27DA 30.5%, #EA2D45 95.74%);
}

.coloredText {
  background: var(--TITLE-GRADIENT-COLOR);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-page-container {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.error-page-text {
	text-align: center;
  color: #f9f9f9;
}

.error-page-text h1 {
  font-family: "Rubik";
  font-size: 12rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.error-page-text h2 {
  margin-top: 11px;
  color: #f9f9f9;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.changePaymentMethodSwitchButton {
  width: 400px;
  height: 40px;
  margin-bottom: 45px;
  display: flex;
  border-radius: 20px;
  text-align: center;
  /* transform: translate3D(-50%, -50%, 0); */
  will-change: transform;
  z-index: 197 !important;
  cursor: pointer;
  transition: 0.3s ease all;
  border: 1px solid white;
}

.switch-button-case {
  display: inline-block;
  background: none;
  width: 49%;
  height: 100%;
  color: white;
  position: relative;
  border: none;
  transition: 0.3s ease all;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding-bottom: 1px;
}

.switch-button-case:hover {
  color: grey;
  cursor: pointer;
}

.switch-button-case:focus {
  outline: none;
}

.changePaymentMethodSwitchButton .active {
  border-radius: 20px;
  color: #151515;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: 100%;
  z-index: -1;
  transition: 0.3s ease-out all;
}

.changePaymentMethodSwitchButton .activeSubscriptionPeriodLeft {
  left: 0;
}

.changePaymentMethodSwitchButton .activeSubscriptionPeriodMiddleLeft {
  left: 25%;
}

.changePaymentMethodSwitchButton .activeSubscriptionPeriodMiddleRight {
  left: 50%;
}

.changePaymentMethodSwitchButton .activeSubscriptionPeriodRight {
  left: 75%;
}

.changePaymentMethodSwitchButton .active-case {
  color: #151515;
}

#pricesPacks .title-container {
  /* margin-bottom: 60px; */
  margin-bottom: 50px;
}

.pricesPacks {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.pricesPacksContainer {
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.pricePack {
  width: 390px;
  height: 760px;
  margin-right: 35px;
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #3b3838;
  border-radius: 20px;
  background-color: #161616;
}

.pricesMostPopular {
  width: fit-content;
  padding: 3.5px 10px;
  position: absolute;
  right: 30px;
  /* border: 1px solid;
  border-image: linear-gradient(30deg, #FF1432, #6A0CFB);
  border-image-slice: 1; */
  background: linear-gradient(#161616, #161616) padding-box,
    linear-gradient(10deg, #ff1432, #6a0cfb) border-box;
  border-radius: 50em;
  border: 1px solid transparent;
  font-family: "Poppins";
  font-size: 11px;
}

.pricePackFirstSection {
}

.pricesPackIcon {
  height: 30px;
  margin-bottom: 20px;
}

.pricesPackCartIcon {
  height: 26px;
}

.pricesPackName {
  margin-bottom: 3px;
  font-family: "Rubik";
  font-weight: 500;
  font-size: 24px;
}

.pricesPackDescription {
  margin-bottom: 18px;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 12px;
  color: #c0c0c0;
}

.pricesPackPriceContainer {
  margin-bottom: 31px;
  font-family: "Rubik";
}

.pricesPackPrice {
  /* margin-bottom: 3px; */
  font-size: 35.7px;
}

.subscriptionPackPrice {
  font-size: 32px;
}

.subscriptionPriceContainer {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.subscriptionPriceAndCommitment {
  display: flex;
  flex-direction: column;
}

.pricesPackHorizontalBar {
  width: 2px;
  height: 40px;
  margin: 0 20px;
  background-color: #fff;
  display: block;
  border-radius: 20px;
}

.subscriptionPrice {
  display: flex;
  align-items: end;
}

.pricesPackSubscriptionPackSchedule {
  font-size: 15px;
}

.pricesPackPaymentModality {
  margin-top: -2px;
  font-size: 12px;
}

.subscriptionPrice p:last-of-type {
  margin-left: 10px;
  margin-bottom: 6px;
}

.pricesPackAdvantage {
  margin-bottom: 14px;
  display: flex;
  align-items: start;
}

.pricesPackAdvantagePackIncluded {
  margin-bottom: 0;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  padding: 8px 10px;
  justify-content: center;
}

.pricesPackAdvantageIcon {
  width: 18px;
  margin-right: 13px;
}

.pricesPackAdvantage p {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 13px;
}

.pricesPackAdvantagePlus {
  display: flex;
  justify-content: center;
  margin: 7px 0;
}

.pricesPackButton {
  width: 100%;
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3b3838;
  border-radius: 200px;
  background-color: #161616;
  font-family: "Raleway";
  font-weight: 500;
  color: #fff;
}

.pricesPackButton:hover {
  border: 1px solid #fff;
}

.pricesPackMainButton {
  background-color: #fff;
  color: #161616;
}

.pricesPackMainButton:hover {
  background-color: #161616;
  color: #ebebeb;
}

.pricePackBusiness {
  height: 790px;
  border: none;
}

.pricePackBusiness::before {
  content: "";
  position: absolute;
  inset: 1px;
  /* border-radius: 28px; */
  background: -o-linear-gradient(336deg, #6a0cfb 4.73%, #ea2d45 100%);
  background: linear-gradient(114deg, #6a0cfb 4.73%, #ea2d45 100%);
  -webkit-filter: blur(16px);
  filter: blur(12px);
  z-index: -1;
}

.pricePackBusiness .pricePackFirstSection {
  margin-top: 37px;
}

.pricePackEcommerce {
  margin-right: 0;
}

/* Responsive */

/* < 481px	Mobile devices
481px — 768px	iPads, Tablets
769px — 1024px	Small screens, laptops
1025px — 1200px	Desktops, large screens
1201px and greater	Extra large screens, TV */

/* Extra large screens, TV */

@media screen and (min-width: 1201px) {
}

/* Desktops, large screens */

@media screen and /* (min-width: 1025px) and */ (max-width: 1400px) and (min-width: 1025px) {
  .changePaymentMethodSwitchButton {
    width: 360px;
    height: 38px;
  }

  .changePaymentMethodSwitchButton button {
    font-size: 11px;
  }

  .pricePack {
    width: 320px;
    height: 690px;
    margin-right: 30px;
    padding: 20px;
    border-radius: 20px;
  }

  .pricesPackPrice {
    font-size: 25px;
  }

  .subscriptionPrice p:last-of-type {
    margin-left: 9px;
    margin-bottom: 4px;
    font-size: 13px;
  }

  .pricesPackIcon {
    height: 25px;
    margin-bottom: 20px;
  }

  .pricesPackCartIcon {
    height: 20px;
  }

  .pricePackBusiness {
    height: 719.5px;
    border: none;
  }

  .pricesMostPopular {
    right: 20px;
    font-size: 11px;
  }

  /* .pricesPackDescription br {
    display: none;
  } */

  .pricesPackName {
    margin-bottom: 3px;
    font-size: 20px;
  }

  .pricesPackDescription {
    margin-bottom: 23px;
    font-size: 12px;
  }

  .pricesPackPaymentModality {
    font-size: 11px;
  }

  .pricesPackHorizontalBar {
    height: 30px;
  }

  .pricesPackAdvantageIcon {
    width: 13px;
    margin-right: 10px;
  }

  .pricesPackAdvantage p:first-of-type {
    margin: 0;
  }

  .pricesPackAdvantage p {
    margin-top: -4px;
    font-size: 12px;
  }

  .pricePackBusiness .pricePackFirstSection {
    margin-top: 25.5px;
  }

  .pricePackEcommerce {
    margin-right: 0;
  }

  .pricesPackButton {
    padding: 11px;
    font-size: 12.5px;
  }
}

/* Small screens, laptops */

@media screen and /* (min-width: 769px) and */ (max-width: 1024px) {
  .changePaymentMethodSwitchButton {
    width: 360px;
    height: 38px;
  }

  .changePaymentMethodSwitchButton button {
    font-size: 11px;
  }

  .pricesPackAdvantagesContainer br {
    content: " ";
  }
  .pricesPackAdvantagesContainer br:after {
    content: " ";
  }

  .pricePack {
    width: 400px;
    height: 740px;
    margin-right: 35px;
    padding: 30px;
    border-radius: 20px;
  }

  .pricesPacksContainer {
    max-width: 1600px;
    display: flex;
    flex-direction: column;
  }

  .pricePack {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .pricePackBusiness {
    height: 790px;
  }

  .pricePackEcommerce {
    margin-right: 0;
    margin-bottom: 0;
  }

  .pricesPackName {
    font-size: 26px;
  }

  .pricesPackDescription {
    font-size: 13px;
  }

  .subscriptionPackPrice {
    font-size: 34px;
  }

  .subscriptionPrice p:last-of-type {
    font-size: 14.5px;
  }

  .pricesPackPaymentModality {
    font-size: 13px;
  }

  .pricesPackAdvantage p {
    font-size: 13.5px;
    margin-top: -3px;
  }
}

/* iPads, Tablets */

@media screen and /* (min-width: 481px) and */ (max-width: 768px) {
}

/* Mobile devices */

@media screen and (max-width: 550px) {
}

@media screen and (max-width: 450px) {
  .changePaymentMethodSwitchButton {
    width: 330px;
    height: 38px;
    font-size: 10px;
  }

  .pricePack {
    width: 350px;
    height: 760px;
    padding: 30px;
    border-radius: 20px;
  }

  .subscriptionPackPrice {
    font-size: 32px;
  }
  
  .pricesPackPaymentModality {
    font-size: 12px;
  }
  
  .pricesPackHorizontalBar {
    height: 30px;
  }

  .pricePackBusiness {
    height: 780px;
  }
}

.landing-page-navigation {
	top: calc(50vh - 64px);
    left: 38px;
    position: fixed;
	z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
    color: #f9f9f9;
    height: 195px;
}

.navigation-dots {
	margin-top: 10px;
	display: flex;
	align-items: center;
}

.navigation-dots ul {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	list-style: none;
}

.navigation-dots ul li {
	width: 8px;
	height: 8px;
	margin-bottom: 9px;
	border-radius: 50%;
	background-color: #3a3a3c;
}

.navigation-dots ul .active-dot {
	background-color: #f9f9f9;
}

ul:last-child {
	margin-right: 0;
}

.next-button {
	transform: rotate(-90deg) translateX(50%);
	display: block;
	font-size: 1rem;
	color: #f9f9f9;
	cursor: pointer;
}

/* Responsive */

/* Mobile devices */

@media screen and (max-width: 481px) {
  
}

/* iPads, Tablets */

@media screen and /* (min-width: 481px) and */ (max-width: 768px) {
  
}

/* Small screens, laptops */

@media screen and /* (min-width: 769px) and */ (max-width: 1300px) {
	.landing-page-navigation {
		display: none;
	}
}

/* Desktops, large screens */

@media screen and /* (min-width: 1025px) and */ (max-width: 1200px) {
  
}

/* Extra large screens, TV */

@media screen and (min-width: 1201px) {
  
}
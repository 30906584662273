.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #212121;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  height: 96px;
  width: 96px;
  animation: rotate_3922 1.2s linear infinite;
  background-color: #9b59b6;
  background-image: linear-gradient(#6a0cfb, #b81193, #ff1432);
}

.loader span {
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: #212121;
  background-image: linear-gradient(#6a0cfb, #b81193, #ff1432);
}

.loader span:nth-of-type(1) {
  filter: blur(5px);
}

.loader span:nth-of-type(2) {
  filter: blur(10px);
}

.loader span:nth-of-type(3) {
  filter: blur(25px);
}

.loader span:nth-of-type(4) {
  filter: blur(50px);
}

.loader::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-color: #212121;
  border: solid 5px #212121;
  opacity: 0.7;
  border-radius: 50%;
}

@keyframes rotate_3922 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
